<template>
  <v-container>
    <v-toolbar flat color="toolbar-action transparent">
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="ma-2" @click="addHost()">Add Host</v-btn>
      <v-btn color="primary" dark class="ma-2" @click="newHost()">New Host</v-btn>
    </v-toolbar>
    <v-alert v-if="hasDuplicates" type="warning">There is a duplicate entries in the name list</v-alert>
    <v-data-table
      :headers="headers"
      :items="organisations"
      :options.sync="options"
      @click:row="editItem"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
      :items-per-page="items_per_page"
    >
      <template v-slot:item.full_allocation="{ item }">
        {{ item.full_allocation }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <Paginate :meta="meta" v-on:paginate="paginateAction" />
    <v-dialog width="480" :fullscreen="$vuetify.breakpoint.xsOnly" v-model="showAddHostDialog">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Add Host</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showAddHostDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <SubgroupAddHostForm :isvisible="showAddHostDialog" @closed="onSubgroupAddHostFormClosed" />
        </v-card>
      </template>
    </v-dialog>
    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showDeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import SubgroupAddHostForm from '@/views/SubgroupAddHostForm';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import Paginate from '@/views/Paginate';

export default {
  name: 'SubgroupHostList',
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store
      .dispatch('organisation/list', {
        subgroup_id: to.params.id,
        page: currentPage,
        per_page: 100,
        sort_by: 'id',
        sort_desc: true,
        full_allocation: true
      })
      .then(() => {
        to.params.page = currentPage;
        next();
      });
  },
  components: {
    ConfirmDialog,
    SubgroupAddHostForm,
    Paginate
  },
  methods: {
    addHost: function () {
      this.showAddHostDialog = true;
    },
    newHost: function () {
      this.$router.push({
        path: `/organisations/new?subgroup_id=${this.subgroup.id}&organisation_types_id=4`
      });
    },
    editItem(item) {
      this.$router.push({
        path: `/organisations/${item.id}?subgroup_id=${this.subgroup.id}`
      });
    },
    deleteItem(item) {
      this.organisation_id = item.id;
      this.showDeleteConfirmDialog = true;
    },
    confirmDelete: function (deleteItem) {
      this.showDeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    async processDelete() {
      await store.dispatch('subgroup/hostDetach', {
        subgroup_id: this.subgroup.id,
        organisation_id: this.organisation_id
      });
      this.load();
    },
    async onSubgroupAddHostFormClosed(props) {
      this.showAddHostDialog = false;
      await store.dispatch('subgroup/hostAttach', {
        subgroup_id: this.subgroup.id,
        organisation_id: props.organisation_id
      });
      this.load();
    },
    load() {
      if (this.subgroup.id) {
        store.dispatch('organisation/list', {
          page: this.options.page,
          per_page: this.items_per_page,
          sort_by: this.options.sortBy[0] ?? 'title',
          sort_desc: this.options.sortDesc[0] ?? false,
          subgroup_id: this.subgroup.id
        });
      }
    },
    paginateAction(item) {
      if (item == 'next') {
        store.dispatch('organisation/paginate', this.links.next);
      } else if (item == 'previous') {
        store.dispatch('organisation/paginate', this.links.prev);
      }
    }
  },
  computed: {
    ...mapGetters('organisation', ['loading', 'error', 'organisations', 'meta', 'links']),
    ...mapGetters('subgroup', ['subgroup'])
  },
  watch: {
    organisations: {
      handler(newValues) {
        let seen = new Set();
        const hasDuplicates = newValues.some(function (currentObject) {
          return seen.size === seen.add(currentObject.title.replace(/[\s,]+/g, '').toLowerCase()).size;
        });
        this.hasDuplicates = hasDuplicates;
      },
      deep: true,
      immediate: true
    }
  },
  data: () => ({
    showDeleteConfirmDialog: false,
    showAddHostDialog: false,
    hasDuplicates: false,
    organisation_id: null,
    items_per_page: 100,
    options: {},
    headers: [
      { text: 'Title', value: 'title' },
      { text: 'Status', value: 'status' },
      { text: 'Allocated', value: 'full_allocation' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ]
  })
};
</script>
