<template>
  <v-container>
    <v-toolbar flat color="toolbar-action transparent">
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="addItem">New Family Member</v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="familyMembers"
      @click:row="editItem"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
      <template v-slot:item.dob="{ item }">
        {{ formatDate(item.dob) }}
      </template>
      <template v-slot:item.card_expire="{ item }">
        {{ formatDate(item.card_expire) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-dialog max-width="600" width="auto" :fullscreen="$vuetify.breakpoint.xsOnly" v-model="showManageDialog">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Family Member Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showManageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <FamilyMemberDetailForm
            :family_member_id="family_member_id"
            :organisation_id="organisation.id"
            :isvisible="showManageDialog"
            @closed="onFlightMangageDialogClosed"
          />
        </v-card>
      </template>
    </v-dialog>
    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showDeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />
  </v-container>
</template>

<script>
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import FamilyMemberDetailForm from '@/views/FamilyMemberDetailForm';
import { dateMixin } from '@/mixins/dateMixin';
import { mapGetters } from 'vuex';
import store from '@/store/index';

export default {
  name: 'OrganisationFamilyMemberList',
  components: {
    FamilyMemberDetailForm,
    ConfirmDialog
  },
  mixins: [dateMixin],
  computed: {
    ...mapGetters('family', ['loading', 'error', 'familyMembers', 'meta']),
    ...mapGetters('organisation', ['organisation'])
  },
  methods: {
    addItem: function () {
      this.family_member_id = 'new';
      this.showManageDialog = true;
    },
    editItem(item) {
      this.family_member_id = item.id;
      this.showManageDialog = true;
    },
    deleteItem(item) {
      this.family_member_id = item.id;
      this.showDeleteConfirmDialog = true;
    },
    confirmDelete: function (deleteItem) {
      this.showDeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    async processDelete() {
      await store.dispatch('family/delete', this.family_member_id);
      this.load();
    },
    onFlightMangageDialogClosed() {
      this.showManageDialog = false;
      this.load();
    },
    load() {
      if (this.organisation.id) {
        store.dispatch('family/list', { page: 1, organisation_id: this.organisation.id });
      }
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    'organisation.id': function () {
      this.load();
    }
  },
  data: () => ({
    showManageDialog: false,
    showDeleteConfirmDialog: false,
    family_member_id: null,
    organisation_id: null,
    headers: [
      { text: 'First Name', value: 'first_name' },
      { text: 'Surname', value: 'surname' },
      { text: 'Age', value: 'age' },
      { text: 'DOB', value: 'dob' },
      { text: 'Relationship', value: 'relationship' },
      { text: 'Card #', value: 'card_number' },
      { text: 'Card Expire', value: 'card_expire' },
      { text: 'Status', value: 'status' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ]
  })
};
</script>
