import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{attrs:{"flat":"","color":"toolbar-action transparent"}},[_c(VSpacer),_c(VBtn,{staticClass:"mb-2",attrs:{"color":"primary","dark":"","to":'/people/new?organisation_id=' + this.organisation.id}},[_vm._v("New Contact")])],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.people,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":""},on:{"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('ConfirmDialog',{attrs:{"title":"Confirm delete","description":"Are you sure you want to delete this item","okayLabel":"Yes","cancelLabel":"No","show":_vm.showDeleteConfirmDialog},on:{"confirm-dialog-clicked":_vm.confirmDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }