<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid, dirty }">
      <v-form>
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider name="First Name" rules="required|min:2|max:60">
              <v-text-field
                v-model="familyMemberLocal.first_name"
                :disabled="loading"
                label="First Name"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Middle Name" rules="min:2|max:60">
              <v-text-field
                v-model="familyMemberLocal.middle_name"
                :disabled="loading"
                label="Middle Name"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Surname" rules="required|min:2|max:60">
              <v-text-field
                v-model="familyMemberLocal.surname"
                :disabled="loading"
                label="Surname"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="DOB Date" rules="required|date">
              <DateInput
                v-model="dob"
                label="DOB Date"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Relationship" rules="required">
              <v-select
                v-model="familyMemberLocal.family_member_relationships_id"
                :disabled="loading"
                :items="lists.relationships"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Relationship"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="age" disabled label="Age" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Card #" rules="min:2">
              <v-text-field
                v-model="familyMemberLocal.card_number"
                :disabled="loading"
                label="Card #"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Card Expire" rules="date">
              <DateInput
                v-model="familyMemberLocal.card_expire"
                label="Card Expire"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Status" rules="required">
              <v-select
                v-model="familyMemberLocal.family_member_statuses_id"
                :disabled="loading"
                :items="lists.status"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Status"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="saveAction" :disabled="loading || invalid || !dirty" class="primary">Save</v-btn>
        <v-btn
          @click="deleteAction"
          :disabled="loading || family_member_id == 'new'"
          outlined
          color="primary"
          class="ma-2"
          >Delete</v-btn
        >
      </v-form>
    </ValidationObserver>
    <ConfirmDialog :show="showNavigateAwayConfirmDialog" @confirm-dialog-clicked="confirmNavigate" />
  </v-container>
</template>

<script>
import { differenceInYears } from 'date-fns';
import loadDash from 'lodash';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, min, max } from 'vee-validate/dist/rules';
import { date } from '@/utils/helpers';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import DateInput from '@/views/DateInput';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';

extend('required', required);
extend('min', min);
extend('max', max);
extend('date', date);

export default {
  name: 'FamilyMemberDetailForm',
  beforeRouteLeave(to, from, next) {
    if (this.isDirty()) {
      this.next = next;
      this.showNavigateAwayConfirmDialog = true;
    } else {
      next();
    }
  },
  components: {
    ConfirmDialog,
    DateInput,
    ValidationProvider,
    ValidationObserver
  },
  props: ['family_member_id', 'organisation_id', 'isvisible'],
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('family', ['loading', 'familyMember', 'error', 'success', 'meta']),
    /*
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        status: [],
        relationships: []
      };
    },
    /**
     * To work with v-model and vuex, we only want to keep
     * a local copy until the user is ready to save.
     */
    familyMemberLocal: function () {
      return loadDash.cloneDeep(this.familyMember);
    }
  },
  methods: {
    /**
     * Load the familyMember
     */
    loadAction: function () {
      if (this.family_member_id) {
        this.resetValidation();
        store.dispatch('family/get', this.family_member_id);
      }
    },
    /**
     * Save method for the form
     */
    async saveAction() {
      const result = await this.$refs.objects.validate();
      if (result) {
        this.familyMemberLocal.organisation_id = this.organisation_id;
        this.familyMemberLocal.dob = this.dob;
        await store.dispatch('family/save', {
          id: this.familyMemberLocal.id,
          familyMember: this.familyMemberLocal
        });
        this.resetValidation();
        this.$emit('closed');
      }
    },
    /**
     * Delete action for the form
     */
    deleteAction() {
      store.dispatch('family/delete', this.family_member_id);
      this.$emit('closed');
    },
    /**
     * Check if this a new record
     */
    isNewRecord() {
      return this.$route.params.id == 'new' ? true : false;
    },
    /**
     * Reset the validation, this will make sure the dirty flags get set to
     * false after save action.
     */
    resetValidation() {
      requestAnimationFrame(() => {
        this.$refs.objects.reset();
      });
    },
    /**
     * Check if the form has changed after loading
     */
    isDirty() {
      return this.$refs.objects._data.flags.dirty;
    },
    confirmNavigate: function (navigateAway) {
      this.showNavigateAwayConfirmDialog = false;
      if (navigateAway) {
        this.next();
      }
    }
  },
  mounted: async function () {
    this.loadAction();
  },
  watch: {
    'familyMemberLocal.dob': function (newValue) {
      this.dob = newValue;
    },
    dob: function (newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        const dob = new Date(Date.parse(newValue + ' 00:00:00'));
        this.age = differenceInYears(new Date(), dob);
      }
    },
    isvisible: function (newValue) {
      if (newValue) {
        this.loadAction();
      }
    },
    success: function () {
      this.$emit('closed');
    }
  },
  data: () => ({
    age: 0,
    dob: '',
    showNavigateAwayConfirmDialog: false
  })
};
</script>
