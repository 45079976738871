import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{attrs:{"flat":"","color":"toolbar-action transparent"}},[_c(VSpacer),_c(VBtn,{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.addItem}},[_vm._v("New Family Member")])],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.familyMembers,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":""},on:{"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"item.dob",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dob))+" ")]}},{key:"item.card_expire",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.card_expire))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c(VDialog,{attrs:{"max-width":"600","width":"auto","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.showManageDialog),callback:function ($$v) {_vm.showManageDialog=$$v},expression:"showManageDialog"}},[[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c(VToolbarTitle,[_vm._v("Family Member Details")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showManageDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('FamilyMemberDetailForm',{attrs:{"family_member_id":_vm.family_member_id,"organisation_id":_vm.organisation.id,"isvisible":_vm.showManageDialog},on:{"closed":_vm.onFlightMangageDialogClosed}})],1)]],2),_c('ConfirmDialog',{attrs:{"title":"Confirm delete","description":"Are you sure you want to delete this item","okayLabel":"Yes","cancelLabel":"No","show":_vm.showDeleteConfirmDialog},on:{"confirm-dialog-clicked":_vm.confirmDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }