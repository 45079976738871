<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid }">
      <v-form>
        <v-row>
          <v-col cols="12">
            <ValidationProvider name="Host" rules="required">
              <v-autocomplete
                v-model="organisation_id"
                :loading="loading"
                :items="organisations"
                :search-input.sync="search"
                no-data-text="Host not found"
                item-text="title"
                item-value="id"
                auto-select-first
                clearable
                cache-items
                flat
                hide-no-data
                hide-details
                label="Host"
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.title"></v-list-item-title>
                      <v-list-item-subtitle
                        v-if="data.item.subtitle"
                        v-html="data.item.subtitle"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
                <template v-slot:append-item>
                  <div v-intersect="endIntersect" />
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn @click="okAction" :disabled="loading || invalid" class="primary">Ok</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import OrganisationService from '@/services/OrganisationService';

extend('required', required);

export default {
  name: 'SubgroupAddHostForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  watch: {
    search(value) {
      if (this.loading) return false;
      if (value == null || value == '') return false;
      if (value && value.indexOf(',') > 0) return false;
      this.searchTerm = value;
      this.fetchEntriesDebounced();
    }
  },
  methods: {
    /**
     * Pagniate autocomplete
     */
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (this.nextPage != null) {
          this.loading = true;
          OrganisationService.paginate(this.nextPage).then(response => {
            this.nextPage = response.data.links.next;
            this.organisations = [...this.organisations, ...response.data.data];
            this.loading = false;
          });
        }
      }
    },

    /**
     * Fetch the data with a debounced event to reduce calls to server
     */
    fetchEntriesDebounced() {
      clearTimeout(this.timerID);
      this.timerID = setTimeout(() => {
        this.searchAction();
      }, 1000);
    },
    /**
     * Search for the organisation
     */
    searchAction() {
      this.loading = true;
      this.nextPage = null;
      OrganisationService.search({
        keyword: this.searchTerm,
        subtitle: 1,
        sort_by: 'title',
        sort_desc: 'asc'
      }).then(response => {
        this.nextPage = response.data.links.next;
        this.organisations = response.data.data;
        this.loading = false;
      });
    },
    /**
     * Save method for the form
     */
    async okAction() {
      this.$emit('closed', {
        organisation_id: this.organisation_id
      });
      this.organisation_id = null;
    }
  },
  data: () => ({
    organisations: [],
    organisation_id: null,
    search: null,
    timerID: null,
    searchTerm: null,
    loading: false,
    currentPage: 0,
    numberOfPages: 0,
    nextPage: ''
  })
};
</script>
