<template>
  <v-container>
    <v-toolbar flat color="toolbar-action transparent">
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" :to="'/people/new?organisation_id=' + this.organisation.id"
        >New Contact</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="people"
      @click:row="editItem"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showDeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />
  </v-container>
</template>

<script>
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import { mapGetters } from 'vuex';
import store from '@/store/index';

export default {
  name: 'OrganisationContactList',
  components: {
    ConfirmDialog
  },
  computed: {
    ...mapGetters('person', ['loading', 'error', 'people', 'meta']),
    ...mapGetters('organisation', ['organisation'])
  },
  methods: {
    editItem(item) {
      this.$router.push({ path: `/people/${item.id}` });
    },
    deleteItem(item) {
      this.person_id = item.id;
      this.showDeleteConfirmDialog = true;
    },
    confirmDelete: function (deleteItem) {
      this.showDeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    async processDelete() {
      await store.dispatch('person/delete', this.person_id);
      this.load();
    },
    load() {
      if (this.organisation.id) {
        store.dispatch('person/list', { page: 1, organisation_id: this.organisation.id });
      }
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    'organisation.id': function () {
      this.load();
    }
  },
  data: () => ({
    showDeleteConfirmDialog: false,
    headers: [
      { text: 'First Name', value: 'first_name' },
      { text: 'Surname', value: 'surname' },
      { text: 'Status', value: 'status' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ]
  })
};
</script>
