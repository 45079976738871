import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{attrs:{"flat":"","color":"toolbar-action transparent"}},[_c(VSpacer),_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.addHost()}}},[_vm._v("Add Host")]),_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.newHost()}}},[_vm._v("New Host")])],1),(_vm.hasDuplicates)?_c(VAlert,{attrs:{"type":"warning"}},[_vm._v("There is a duplicate entries in the name list")]):_vm._e(),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.organisations,"options":_vm.options,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":"","items-per-page":_vm.items_per_page},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"item.full_allocation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.full_allocation)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('Paginate',{attrs:{"meta":_vm.meta},on:{"paginate":_vm.paginateAction}}),_c(VDialog,{attrs:{"width":"480","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.showAddHostDialog),callback:function ($$v) {_vm.showAddHostDialog=$$v},expression:"showAddHostDialog"}},[[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c(VToolbarTitle,[_vm._v("Add Host")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showAddHostDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('SubgroupAddHostForm',{attrs:{"isvisible":_vm.showAddHostDialog},on:{"closed":_vm.onSubgroupAddHostFormClosed}})],1)]],2),_c('ConfirmDialog',{attrs:{"title":"Confirm delete","description":"Are you sure you want to delete this item","okayLabel":"Yes","cancelLabel":"No","show":_vm.showDeleteConfirmDialog},on:{"confirm-dialog-clicked":_vm.confirmDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }