import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Host","rules":"required"}},[_c(VAutocomplete,{attrs:{"loading":_vm.loading,"items":_vm.organisations,"search-input":_vm.search,"no-data-text":"Host not found","item-text":"title","item-value":"id","auto-select-first":"","clearable":"","cache-items":"","flat":"","hide-no-data":"","hide-details":"","label":"Host"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(data.item.title)}}),(data.item.subtitle)?_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(data.item.subtitle)}}):_vm._e()],1)]]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}]})]},proxy:true}],null,true),model:{value:(_vm.organisation_id),callback:function ($$v) {_vm.organisation_id=$$v},expression:"organisation_id"}})],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid},on:{"click":_vm.okAction}},[_vm._v("Ok")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }